import { Carousel } from 'antd'
import React from 'react'

export default function AppScreen() {
    return (
        <>
            <div className='temple_list'>
                <h1>
                    Inside Application
                </h1>
            </div>
            
            <div style={{ overflow: 'hidden',marginBottom:'40px' }}>
                <Carousel
                    slidesToShow={4}
                    autoplay
                    infinite={true}
                    arrows={true}
                    dots={true}
                >
                    <div className='slider_slides'>
                        <img src={require('../../../static/img/register.png')} alt="App Screens" />
                    </div>

                    <div className='slider_slides'>
                        <img src={require('../../../static/img/Home.png')} alt="App Screens" />
                    </div>

                    <div className='slider_slides'>
                        <img src={require('../../../static/img/list.png')} alt="App Screens" />
                    </div>

                    <div className='slider_slides'>
                        <img src={require('../../../static/img/details.png')} alt="App Screens" />
                    </div>

                    <div className='slider_slides'>
                        <img src={require('../../../static/img/Calendar.png')} alt="App Screens" />
                    </div>
                </Carousel>
            </div>
        </>
    )
}
