import React from 'react'

export default function TempleList() {
    return (
        <div className='temple_list'>
            <h1>
                Temples
            </h1>

            <div className='cards-list'>
                <div className="card 1">
                    <div className="card_image">
                        <img src={require('../../../static/img/Madurai_Meenakshi.jpg')} alt="temple img" />
                    </div>
                    <div className="card_title title-white">
                        <p>Madurai Meenakshi</p>
                    </div>
                </div>

                <div className="card 1">
                    <div className="card_image">
                        <img src={require('../../../static/img/mahakal.jpg')} alt="temple img" />
                    </div>
                    <div className="card_title title-white">
                        <p>Mahakal, Ujjain</p>
                    </div>
                </div>

                <div className="card 1">
                    <div className="card_image">
                        <img src={require('../../../static/img/sai_baba.jpg')} alt="temple img" />
                    </div>
                    <div className="card_title title-white">
                        <p>Shirdi Sai Baba</p>
                    </div>
                </div>

                <div className="card 1">
                    <div className="card_image">
                        <img src={require('../../../static/img/iskon_mumbai.jpeg')} alt="temple img" />
                    </div>
                    <div className="card_title title-white">
                        <p>Iskon, Mumbai</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
