import React, { useState } from 'react'
import axios from 'axios';
import { Row, Col, message } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';


export default function AddCalenderEvent() {
    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Add Calender Event',
        },
    ];

    const [EventData, setEventdata] = useState({
        name: '',
        description: '',
        date: ''
    });


    const handle = (e) => {
        const newdata = { ...EventData };
        newdata[e.target.name] = e.target.value;
        setEventdata(newdata);
    }

    const AddcalenderEvent=()=>{
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}calendarEvent/add-event`,{
            name:EventData.name,
            description:EventData.description,
            date:EventData.date.split("T")[0]
        })
        .then((res)=>{
            message.success("Calendar Event Added");
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="Add Event" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <input type="text" name="name" onChange={(e) => { handle(e) }} placeholder='Enter Name' />

                                <textarea name="description" onChange={(e) => { handle(e) }} placeholder='Enter Description'/>

                                <input type="date" name="date" onChange={(e) => { handle(e) }} />

                                <button type='button' onClick={()=>{AddcalenderEvent()}}>
                                    Add Calender Event
                                </button>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
