import { Layout } from 'antd'
import React from 'react'
import Header from './Main web/Header'
import './Main web/style.css'
import TempleList from './Main web/TempleList'
import AppScreen from './Main web/AppScreen'
import Aboutus from './Main web/Aboutus'
import Footer from './Main web/Footer'

export default function Home() {
    return (
        <Layout>
            <Header />

            <Aboutus />

            <TempleList />

            <AppScreen />

            <Footer/>
        </Layout>
    )
}
