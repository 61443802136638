import { UilSearchAlt, UilPlusCircle, UilEye, UilBell } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  // const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/starter`}>
        Live Stream
      </NavLink>,
      'starter',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/starter`}>
          <UilSearchAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/LiveMandir`}>
       View Live Mandir
      </NavLink>,
      'LiveMandir',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/LiveMandir`}>
          <UilSearchAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/AddEvent`}>
        Add Event
      </NavLink>,
      'AddEvent',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/AddEvent`}>
          <UilPlusCircle />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/AddSignificace`}>
        Add Significace
      </NavLink>,
      'AddSignificace',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/AddSignificace`}>
          <UilPlusCircle />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/ViewSignificace`}>
        View Significace
      </NavLink>,
      'ViewSignificace',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/ViewSignificace`}>
          <UilEye />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/ViewEvent`}>
        View Events
      </NavLink>,
      'ViewEvent',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/ViewEvent`}>
          <UilEye />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/Addhistory`}>
        Add History
      </NavLink>,
      'Addhistory',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/Addhistory`}>
          <UilPlusCircle />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/Viewhistory`}>
        View History
      </NavLink>,
      'Viewhistory',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/Viewhistory`}>
          <UilEye />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/SendSuvichar`}>
        Send Suvichar
      </NavLink>,
      'SendSuvichar',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/SendSuvichar`}>
          <UilBell />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/EventCalender`}>
        Calender Event
      </NavLink>,
      'EventCalender',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/EventCalender`}>
          <UilPlusCircle />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/AddVideo`}>
        Add Entertainment Videos
      </NavLink>,
      'AddVideo',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/AddVideo`}>
          <UilPlusCircle />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/AddBook`}>
        Add Entertainment PDF
      </NavLink>,
      'AddBook',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/AddBook`}>
          <UilPlusCircle />
        </NavLink>
      ),
    )
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
