import React from 'react'

export default function Aboutus() {
    return (
        <div>
            <div className='temple_list'>
                <h1>
                    About us
                </h1>

                <p className='about_content'>
                    Welcome to Man Mandir, your gateway to the spiritual and cultural richness of India. Immerse yourself in the divine world of Indian temples and experience the magic of live aarti ceremonies, all from the comfort of your mobile device.
                    <br />
                    <b>Our Mission</b>
                    <br />
                    At Man Mandir, we are on a mission to bring the sacred atmosphere of Indian temples to you, no matter where you are in the world. We understand the importance of spirituality and the profound impact it can have on one&apos;s life. Our app is designed to provide you with a unique opportunity to connect with the spiritual heritage of India.
                    <br />
                    <b>
                        Key Features
                    </b>
                    <br />
                    <b>Live Temple Streams:</b> Experience the beauty and serenity of Indian temples through our live streaming feature. Explore stunning architecture, intricate rituals, and the tranquil surroundings of temples right on your screen.<br />
                    <b>Aarti Notifications:</b> Never miss a moment of spiritual significance. Receive timely notifications when aarti ceremonies are about to begin, allowing you to join in the devotion and prayers from afar.<br />
                    <b>Cultural Insights:</b> Discover the history, significance, and cultural aspects of various Indian temples. Learn about the traditions and stories that make each temple unique.<br />
                    <b>User-Friendly:</b> We&apos;ve designed the Man Mandir app to be user-friendly and accessible to everyone. You don&apos;t need to be a tech expert to navigate the spiritual journey we offer.<br />
                    <br />

                    <b>
                        Your Spiritual Companion
                    </b>
                    <br />
                    Man Mandir is not just an app; it&apos;s your spiritual companion. Whether you&apos;re a devout follower, an enthusiast of Indian culture, or simply seeking moments of peace and reflection, we&apos;re here to provide you with a deeper connection to the spirituality of India.
                    <br />

                    <b>
                        Join Us
                    </b>
                    <br />
                    Join us on this spiritual journey as we explore the enchanting world of Indian temples and aarti ceremonies. Download the Man Mandir app today and experience the divine beauty of India&apos;s sacred spaces right in the palm of your hand.
                    <br />

                    <b>
                        Contact Us
                    </b>
                    <br />
                    If you have any questions or suggestions, we&apos;d love to hear from you. Contact us at contact@manmandirapp.com. Your feedback is invaluable as we continue to enhance your spiritual experience with Man Mandir.
                    <br />

                    Thank you for being a part of our community.<b> Namaste!</b>
                </p>
            </div>
        </div>
    )
}
