import React,{useEffect,useState} from 'react'
import { Row, Col, message, Spin, Table } from 'antd';
import axios from 'axios';
import { PageHeader } from '../../components/page-headers/page-headers';
import "antd/dist/antd.css";
import { Main } from '../styled';
import './style.css'
import { Cards } from '../../components/cards/frame/cards-frame';
import { baseURL } from '../../config';

export default function Viewhistory() {
    const PageRoutes = [
        {
            path: 'index',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'View History',
        },
    ];

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    const [data,setData]=useState();

    const ViewHistory=()=>{
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}history/get-all-history`)
        .then((res)=>{
            setData(res.data.history)
            // data.push(res.data.history);
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        ViewHistory()
    },[])


    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="View History" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <Table columns={columns} dataSource={data} />
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
