import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Row, Col } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';


export default function ViewEvents() {

    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'View Events',
        },
    ];

    const [AllEvent, setAllEvent] = useState();

    const getEvent = () => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}event/get-all-events`)
            .then((res) => {
                setAllEvent(res.data.event);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const DeleteEvent=(name)=>{
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}event/delete-event/${name}`)
        .then((res)=>{
            console.log(res)
            alert('Recorde deleted');
            getEvent()
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        getEvent()
    }, [])

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="View significance" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <table className='search_table'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Serial No.
                                            </th>

                                            <th>
                                                Name
                                            </th>

                                            <th>
                                                Image
                                            </th>

                                            <th>
                                                Location
                                            </th>

                                            <th>
                                                Date
                                            </th>

                                            <th>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            AllEvent
                                                ?
                                                AllEvent.map((elem, index) => {
                                                    return (
                                                        <tr style={{ textAlign: 'center' }}>
                                                            <td>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {elem.name}
                                                            </td>

                                                            <td>
                                                                {elem.image}
                                                            </td>

                                                            <td>
                                                                {elem.location}
                                                            </td>

                                                            <td>
                                                                {elem.date.split('T')[0]}
                                                            </td>

                                                            <td>
                                                                <button type='button' onClick={() => { DeleteEvent(elem.name) }}>
                                                                    <i className="fa fa-trash" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main >
        </>
    )
}
