import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { Row, Col, Table, message, Modal, TimePicker, Carousel } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import storage from './firebase'
import { baseURL } from '../../config';
import moment from 'moment';

export default function LiveMandir() {

    const [AllMandir, setAllMandir] = useState(null);

    const [showModal, setShowModal] = useState(null);

    const [liveLink, setLiveLink] = useState('');

    const [Mandirstatus, setStatus] = useState();

    const [mandirName, setMandirName] = useState();

    const [descriptions, setDescription] = useState();

    const [locations, setLocation] = useState();

    const [offlineLink, setOfflineLink] = useState();

    const [aarti, setAarti] = useState([]);

    const [imagess, setImage] = useState([]);

    const [imageName, setimageName] = useState();

    const [selectedTime, setSelectedTime] = useState(null);

    const [imageIndex, setImageIndex] = useState();

    const UploadImageRef = useRef(null);

    const NewUploadImageRef = useRef(null);


    const TimeRef = useRef(null)

    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'View Live Mandir',
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Live Link',
            dataIndex: 'video_id',
        },
        {
            title: 'Offline Link',
            dataIndex: 'offline_video',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) => <button type='button' className='edit_btn' onClick={() => { setShowModal(true); setMandirName(record.name); setDescription(record.description); setLocation(record.location); setImage(record.image); setAarti(record.aarti_time) }}> <i className="fa fa-edit" /> </button>,
        }
    ];

    const GetAllMandir = () => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}mandir/get-all-mandirs`)
            .then((res) => {
                setAllMandir(res.data.mandir)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const EditMandir = () => {
        if (Mandirstatus == null) {
            message.error("Please Select status")
        }
        else {
            axios.patch(`${process.env.REACT_APP_API_ENDPOINT}mandir/update-mandir/${mandirName}`, {
                description: descriptions,
                location: locations,
                aarti_time: aarti,
                video_id: liveLink,
                offline_video: offlineLink,
                status: Mandirstatus,
                image: imagess
            })
                .then(() => {
                    message.success(`${mandirName} is ${Mandirstatus} status updated`);
                    setShowModal(false);
                    GetAllMandir();
                })
                .catch(() => {
                    message.error("There is Some error try again later")
                })
        }

    }

    const EditImage = (newURL) => {
        imagess[imageIndex] = `https://firebasestorage.googleapis.com/v0/b/devdarshan-4df08.appspot.com/o/admin%2Fmandir%2F${newURL}?alt=media&token=380a501e-765f-4608-8d67-d400cd7f9ba5`;
        console.log(imagess)
    }

    const uploadImage = (images) => {
        setimageName(images.lastModified)
        if (images == null)
            return;
        storage.ref(`/admin/mandir/${images.lastModified}`).put(images)
            .on("state_changed", alert("success"), alert);

        EditImage(images.lastModified)
    }

    const NewuploadImage = (images) => {
        setimageName(images.lastModified)
        if (images == null)
            return;
        storage.ref(`/admin/mandir/${images.lastModified}`).put(images)
            .on("state_changed", alert("success"), alert);

        imagess.push(`https://firebasestorage.googleapis.com/v0/b/devdarshan-4df08.appspot.com/o/admin%2Fmandir%2F${images.lastModified}?alt=media&token=380a501e-765f-4608-8d67-d400cd7f9ba5`)
    }



    useEffect(() => {
        GetAllMandir()
    }, [])


    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="View Mandir List" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <Table columns={columns} dataSource={AllMandir} />
                        </Cards>
                    </Col>
                </Row>

                <Modal title="Edit Mandir Status"
                    visible={showModal}
                    onOk={() => {
                        EditMandir()
                    }}
                    onCancel={() => {
                        setShowModal(false);
                    }}
                >

                    <div className='input_live_form'>
                        <table className='table'>
                            <tr>
                                <td>
                                    <span>Image</span>
                                </td>

                                <td>
                                    {/* {
                                        imageName != null
                                            ?
                                            // console.log(`https://firebasestorage.googleapis.com/v0/b/devdarshan-4df08.appspot.com/o/admin%2Fmandir%2F${imageName}?alt=media&token=380a501e-765f-4608-8d67-d400cd7f9ba5`)
                                            <img src={`https://firebasestorage.googleapis.com/v0/b/devdarshan-4df08.appspot.com/o/admin%2Fmandir%2F${imageName}?alt=media&token=380a501e-765f-4608-8d67-d400cd7f9ba5`} alt="Mandir's thumbnails" className='thumbnail_img' />
                                            :
                                            <img src={image} alt="Mandir's thumbnails" className='thumbnail_img' />

                                    } */}

                                    <Carousel dotPosition='right' autoplay>
                                        {
                                            imagess.map((elem, index) => {
                                                return (
                                                    <>
                                                        <img src={elem} alt="Mandir's thumbnails" className='thumbnail_img' />
                                                        <button type='button' className='edit_btn_image' onClick={() => { UploadImageRef.current.click(); setImageIndex(index) }}>
                                                            <i className="fas fa-edit" />
                                                        </button>
                                                    </>
                                                )
                                            })
                                        }
                                    </Carousel>

                                    <button type='button' className='add_new' onClick={() => { NewUploadImageRef.current.click() }}>
                                        <i className="fas fa-plus" />
                                    </button>

                                    <input type="file" style={{ display: 'none' }} ref={NewUploadImageRef} onChange={(e) => { NewuploadImage(e.target.files[0]) }} alt="upload pic" />


                                    <input type="file" style={{ display: 'none' }} ref={UploadImageRef} onChange={(e) => { uploadImage(e.target.files[0]) }} alt="upload pic" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Name</span>
                                </td>

                                <td>
                                    <input type="text" name="Name" value={mandirName} onChange={(e) => { setMandirName(e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Description</span>
                                </td>

                                <td>
                                    <textarea value={descriptions} onChange={(e) => { setDescription(e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Location</span>
                                </td>

                                <td>
                                    <input type="text" name="location" value={locations} onChange={(e) => { setLocation(e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Update Live Link</span>
                                </td>

                                <td>
                                    <input type="text" name="Name" onChange={(e) => { setLiveLink(e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Offline Link</span>
                                </td>

                                <td>
                                    <input type="text" name="Name" onChange={(e) => { setOfflineLink(e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Aarti Time</span>
                                </td>

                                <td className='d-flex'>
                                    <select onClick={(e) => { setSelectedTime(e.target.value); }}>
                                        {aarti.map((elem, index) => {
                                            return (
                                                <option value={index}>{elem}</option>
                                            )
                                        })}
                                    </select>
                                    <TimePicker
                                        format='HH:mm'
                                        value={moment(aarti[selectedTime], 'HH:mm')}
                                        onChange={(value, dateString) => {
                                            aarti[selectedTime] = dateString

                                            // aarti.push(dateString);
                                        }}
                                        className='time_picker mx-1'
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span>Update Status</span>
                                </td>

                                <td>
                                    <select onChange={(e) => { setStatus(e.target.value) }}>
                                        <option selected hidden>
                                            Select status
                                        </option>

                                        <option value="live">
                                            Live
                                        </option>

                                        <option value="offline">
                                            Offline
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                </Modal>
            </Main >
        </>
    )
}
