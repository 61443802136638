import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">

                    <div className="footer-col">
                        <img src={require('../../../static/img/mandir_logo.png')} alt='mandir logo' />
                    </div>

                    <div className="footer-col">
                        <h4>Man Mandir</h4>
                        <ul>
                            <li><a href="#">about us</a></li>
                            <li><Link to="/login/Privacy">privacy policy</Link></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>Download App</h4>
                        <ul>
                            <li><a href="#">Google Play Store</a></li>
                            <li><a href="#">Apple App Store</a></li>
                        </ul>
                    </div>
                    {/* <div className="footer-col">
                        <h4>follow us</h4>
                        <div className="social-links">
                            <a href="#"><i className="fab fa-facebook-f" /> </a>
                            <a href="#"><i className="fab fa-twitter" /> </a>
                            <a href="#"><i className="fab fa-instagram" /> </a>
                            <a href="#"><i className="fab fa-linkedin-in" /> </a>
                        </div>
                    </div> */}

                    <div className="footer-col">
                        <h4>Contact us</h4>
                        <div className="social-links">
                            <a href="mailto:contact@manmandirapp.com"><i className="fas fa-envelope"/> </a>
                        </div>
                    </div>
                </div>
                &#169; Man Mandir, 2023
            </div>
        </footer>
    )
}
