import { message } from 'antd'
import React from 'react'

export default function AccountDelete() {
    const DeleteAccount=()=>{
        message.success("Account Deleted");
        window.location.reload();
    }

    return (
        <div>
            <div className='text-center mt-3'>
                <h1>
                    Request to Delete Your Account
                </h1>
            </div>

            <div className="form-container">
                <input
                    type="text"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                    className="form-input"
                    placeholder='Enter Your Name'
                    required
                />
                <input
                    type="tel"
                    placeholder='Enter Register Mobile Number'
                    // value={mobileNumber}
                    // onChange={(e) => setMobileNumber(e.target.value)}
                    className="form-input"
                    required
                />

                <textarea className="form-input my-2" placeholder='Why you want to Delete your account' />
                <button type="submit" className="form-button" onClick={()=>{DeleteAccount()}}>Submit</button>
            </div>
        </div>
    )
}
