import React, { useState } from 'react'
import axios from 'axios';
import { Row, Col } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';

export default function AddEvent() {
    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Add Event',
        },
    ];

    const [EventData, setEventdata] = useState({
        name: '',
        location: '',
        date: ''
    });

    const [EventImage, setEventImage] = useState('')

    const handle = (e) => {
        const newdata = { ...EventData };
        newdata[e.target.name] = e.target.value;
        setEventdata(newdata);
    }

    const handelImage = (e) => {
        const File = e.target.files[0];
        const formData = new FormData();
        formData.append("image", File)
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}image/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                setEventImage(res.data.imageUrl);
                console.log(res)
                // return res.data.imageUrl
            })
            .catch((err) => {
                // return err
                console.log(err)
            })

    }

    const sendNotifcation = (name,location,date) => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/user/profile`)
            .then((res) => {
                console.log("length",res.data.user.length);
                for (let i = 0; i <= res.data.user.length - 1; i++) {
                    console.log(res.data.user[i].token)
                    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/notification/send`, {
                        tokens: [res.data.user[i].token],
                        title: "New Event....",
                        body: `New Event ${name}  at ${location}, ${date}`
                    }).then((ress) => {
                        console.log(ress.data)
                    })
                        .catch((err) => {
                            console.log("sender notification error", err)
                        })
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const EventAdd = () => {
        // console.log(EventImage)
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}event/add-event`, {
            name: EventData.name,
            image: EventImage,
            location: EventData.location,
            date: EventData.date
        })
            .then((res) => {
                sendNotifcation(EventData.name,EventData.location,EventData.date);
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="Add Event" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <input type="text" name="name" onChange={(e) => { handle(e) }} placeholder='Enter Name' />

                                <input type="file" name="Image" onChange={(e) => { handelImage(e) }} />

                                <input type="text" name="location" onChange={(e) => { handle(e) }} placeholder='Enter Location' />

                                <input type="date" name="date" onChange={(e) => { handle(e) }} />

                                <button type='button' onClick={() => { EventAdd() }}>
                                    Add Event
                                </button>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
