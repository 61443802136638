import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <div className='header_section'>
            <div className='d-flex align-items-center w-100 justify-content-between headers'>
                <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <div>
                            <img src={require('../../../static/img/mandir_logo.png')} alt='manidr logo' className='logo_img' />
                        </div>

                        <div>
                            <p className='logo_name'>
                                Man Mandir
                            </p>
                        </div>
                    </div>

                    <div>
                        <a href='https://play.google.com/store/apps/details?id=com.jptech.manmandir&pcampaignid=web_share' target='_blanks'>
                            <img src={require('../../../static/img/play-badge.png')} alt='play logo' className='store_logo' />
                        </a>

                        <a href='https://apps.apple.com/in/app/man-mandir/id6469785057' target='_blanks'>
                            <img src={require('../../../static/img/apple-badge.png')} alt='play logo' className='store_logo' />
                        </a>
                    </div>
                </div>


                <div>
                    <Link to="/login/Privacy" className='Privacy_link'>
                        Privacy Policy
                    </Link>

                    <Link to="/login/DeleteAccount" className='Privacy_link'>
                        Request to Delete Account
                    </Link>

                    <Link to="login" className='login_btn'>
                        Login As Admin
                    </Link>
                </div>

            </div>

        </div>
    )
}
