import React, { useState } from 'react'
import axios from 'axios';
import { Row, Col, message } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';


export default function EntertainmentVideo() {
    const [Title,setTitle]=useState();
    const [Thumbnail,setThumbnail]=useState();
    const [Video,setVideo]=useState();
    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Add Entertainment Videos',
        },
    ];

    const UploadVideos=async()=>{
        const formData=new FormData()

        formData.append("title",Title)
        formData.append("thumbnail",Thumbnail)
        formData.append("video",Video)
        formData.append("time","2:30")
        formData.append("views","0");

        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/entertainment-1/upload`,formData)
        .then((res)=>{
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="Add Entertainment Videos" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <input type="text" name="name" onChange={(e)=>{setTitle(e.target.value)}} placeholder='Enter Title of Video' />

                                <div className='d-flex'>
                                    <p>
                                        Upload <br /> Thumbnail
                                    </p>
                                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e)=>{setThumbnail(e.target.files[0])}} className='mx-2' name="name" placeholder='Enter Title of Video' />
                                </div>

                                <div className='d-flex'>
                                    <p>
                                        Upload Video
                                    </p>
                                    <input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={(e)=>{setVideo(e.target.files[0])}} className='mx-2' name="name" placeholder='Enter Title of Video' />
                                </div>

                                <button type='button' onClick={()=>{UploadVideos()}}>
                                    Upload Entertainment Videos
                                </button>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
