import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import AddEvent from '../../container/pages/AddEvent';
import AddSignificace from '../../container/pages/AddSignificace';
import ViewSignificace from '../../container/pages/ViewSignificace';
import ViewEvents from '../../container/pages/ViewEvents';
import Addhistory from '../../container/pages/Addhistory';
import Viewhistory from '../../container/pages/Viewhistory';
import SendSuvichar from '../../container/pages/SendSuvichar';
import AddCalenderEvent from '../../container/pages/AddCalenderEvent';
import LiveMandir from '../../container/pages/LiveMandir';
import EntertainmentVideo from '../../container/pages/EntertainmentVideo';
import EntertainmentBook from '../../container/pages/EntertainmentBook';

const BlankPage = lazy(() => import('../../container/pages/BlankPage'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="starter" index element={<BlankPage />} />
      <Route path="AddEvent" element={<AddEvent/>}/>
      <Route path="AddSignificace" element={<AddSignificace/>}/>
      <Route path="ViewSignificace" element={<ViewSignificace/>}/>
      <Route path="ViewEvent" element={<ViewEvents/>}/>
      <Route path="Addhistory" element={<Addhistory/>}/>
      <Route path="Viewhistory" element={<Viewhistory/>}/>
      <Route path='SendSuvichar' element={<SendSuvichar/>}/>
      <Route path="EventCalender" element={<AddCalenderEvent/>}/>
      <Route path='LiveMandir' element={<LiveMandir/>}/>
      <Route path='AddVideo' element={<EntertainmentVideo/>}/>
      <Route path='AddBook' element={<EntertainmentBook/>}/>
    </Routes>
  );
}

export default PagesRoute;
