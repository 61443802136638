import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Row, Col, message,Spin } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';

export default function SendSuvichar() {
    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Send Suvichar',
        },
    ];

    const [EventData, setEventdata] = useState({
        title: '',
        body: '',
    });

    const[loader,setLoader]=useState(false)

    const handle = (e) => {
        const newdata = { ...EventData };
        newdata[e.target.name] = e.target.value;
        setEventdata(newdata);
    }

    const sendNotifcation = () => {
        setLoader(true)
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}user/profile`)
            .then((res) => {
                console.log("length", res.data.user.length);
                for (let i = 0; i <= res.data.user.length - 1; i++) {
                    console.log(res.data.user[i].token)
                    axios.post(`${process.env.REACT_APP_API_ENDPOINT}notification/send`, {
                        tokens: [res.data.user[i].token],
                        title: EventData.title,
                        body: EventData.body
                    }).then((ress) => {
                        console.log(ress.data)
                    })
                        .catch((err) => {
                            console.log("sender notification error", err)
                        })
                }
                message.success('Notification Sended');
                setLoader(false);
            })
            .catch((err) => {
                console.log(err)
                setLoader(false);
            })
    }

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="Send Suvichar" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <input type="text" name="title" onChange={(e) => { handle(e) }} placeholder='Enter Name' />

                                <input type="text" name="body" onChange={(e) => { handle(e) }} placeholder='Enter Description' />

                                <button type='button' disabled={loader} onClick={() => { sendNotifcation() }}>
                                    {
                                        loader
                                            ?
                                            <Spin tip="Submitting..." />
                                            :
                                            "Add Today History"
                                    }
                                </button>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
