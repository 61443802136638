import React, { useState } from 'react'
import axios from 'axios';
import { Row, Col, message } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';

export default function EntertainmentBook() {
    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Add Entertainment Book',
        },
    ];

    const [Title,setTitle]=useState();
    const [thumbnail,setThumbnail]=useState();
    const[file,setFile]=useState();


    const UploadVideos=async()=>{
        const formData=new FormData()

        formData.append("name",Title)
        formData.append("thumbnail",thumbnail)
        formData.append("file",file)

        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}entertainment/upload`,formData)
        .then((res)=>{
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="Add Entertainment Book" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <input type="text" name="name" onChange={(e)=>{setTitle(e.target.value)}} placeholder='Enter Title of Book' />

                                <div className='d-flex'>
                                    <p>
                                        Upload <br /> Thumbnail
                                    </p>
                                    <input type="file" onChange={(e)=>{setThumbnail(e.target.files[0])}} accept="image/png, image/gif, image/jpeg" className='mx-2' name="name" placeholder='Enter Title of Thumbnail' />
                                </div>

                                <div className='d-flex'>
                                    <p>
                                        Upload PDF
                                    </p>
                                    <input type="file" onChange={(e)=>{setFile(e.target.files[0])}} accept="application/pdf"  className='mx-2' name="name" placeholder='Enter Title of PDF' />
                                </div>

                                <button type='button'onClick={()=>{UploadVideos()}}>
                                    Upload Entertainment Book
                                </button>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
