import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Row, Col,Table,message } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { baseURL } from '../../config';

export default function ViewSignificace() {

    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'View Significace',
        },
    ];

    const [AllSignificace, setAllSignificace] = useState();

    const getSignificace = () => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}significance/get-all-significance`)
            .then((res) => {
                setAllSignificace(res.data.significance)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const DeleteSignificace=(name)=>{
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}significance/delete-significance/${name}`)
        .then((res)=>{
            console.log(res)
            message.success(`Record deleted ${name}`)
            // alert('Recorde deleted');
            getSignificace()
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key:'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) => <button type='button' onClick={(e)=>{DeleteSignificace(record.name);}}> <i className="fa fa-trash" /> </button>,
          }
    ];

    useEffect(() => {
        getSignificace()
    }, [])

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="View significance" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            {/* <div className='Event_form'>
                                <table className='search_table'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Serial No.
                                            </th>

                                            <th>
                                                Name
                                            </th>

                                            <th>
                                                Description
                                            </th>

                                            <th>
                                                Date
                                            </th>

                                            <th>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            AllSignificace
                                                ?
                                                AllSignificace.map((elem, index) => {
                                                    return (
                                                        <tr style={{ textAlign: 'center' }}>
                                                            <td>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {elem.name}
                                                            </td>
                                                            <td>
                                                                {elem.description}
                                                            </td>

                                                            <td>
                                                                {elem.date.split('T')[0]}
                                                            </td>

                                                            <td>
                                                                <button type='button' onClick={()=>{DeleteSignificace(elem.name)}}>
                                                                    <i className="fa fa-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </tbody>
                                </table>
                            </div> */}
                            <Table columns={columns} dataSource={AllSignificace} />
                        </Cards>
                    </Col>
                </Row>
            </Main >
        </>
    )
}
