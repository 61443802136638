import React,{useState} from 'react'
import { Row, Col,message,Spin } from 'antd';
import axios from 'axios';
import { PageHeader } from '../../components/page-headers/page-headers';
import "antd/dist/antd.css";
import { Main } from '../styled';
import './style.css'
import { Cards } from '../../components/cards/frame/cards-frame';
import { baseURL } from '../../config';

export default function Addhistory() {
    const PageRoutes = [
        {
            path: 'index',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Add Today History',
        },
    ];

    const [EventData, setEventdata] = useState({
        name: '',
        description: '',
        date: ''
    });

    const[loader,setLoader]=useState(false)

    const handle = (e) => {
        const newdata = { ...EventData };
        newdata[e.target.name] = e.target.value;
        setEventdata(newdata);
    }

    const AddHistory=()=>{
        setLoader(true)
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}history/add-history`,{
            title:EventData.name,
            description:EventData.description,
            date:EventData.date
        })
        .then((res)=>{
            console.log(res);
            setLoader(false)
            message.success(`History added on ${EventData.date}`);
        })
        .catch((err)=>{
            setLoader(false)
            console.log(err)
        })
    }
    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title="Add Today History" routes={PageRoutes} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <div className='Event_form'>
                                <input type="text" name="name" onChange={(e)=>{handle(e)}} placeholder='Enter Name' />

                                <input type="text" name="description" onChange={(e)=>{handle(e)}} placeholder='Enter Description' />

                                <input type="date" name="date" onChange={(e)=>{handle(e)}}/>

                                <button type='button' disabled={loader} onClick={()=>{AddHistory()}}>
                                    {
                                        loader
                                        ?
                                        <Spin tip="Submitting..."/>
                                        :
                                        "Add Today History"
                                    }
                                </button>
                            </div>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    )
}
