import React from 'react'

export default function Privacy() {
    return (
        <div style={{ marginLeft: 10, marginRight: 10 }}>
            <h3>
                Privacy Policy for Man Mandir
            </h3>

            <span style={{fontSize:'10px',color:'gray'}}>
                Last Updated: 01 October 2024
            </span>
            <br/>
            <br/>

            <p style={{ textAlign: "justify", marginLeft: 10, marginRight: 10 }}>
                <b>1) Introduction</b>
                <br />
                <br />

                Welcome to Man Mandir! We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy explains how we collect, use, and safeguard your data when you use our app.
                <br />
                <br />

                <b>2) Information We Collect</b>
                <br />
                <br />

                <b> <i>a) User ID:</i></b> When you log in to our app, we collect your unique user identification number. This is solely for the purpose of providing you access to our services.
                <br />
                <b><i>b) Location Information: </i></b> We may collect your device &apos; s location information with your consent to offer you the &quot;Live Indian Temple&quot; feature. This data is used exclusively for this feature, and you can disable it in your device settings at any time.
                <br />
                <b><i>c) Notifications: </i></b> We may request permission to send you notifications about upcoming temple aarti. You can opt-in to receive these notifications, and you can also disable them at any time in your app settings or device settings.
                How We Use Your Information

                <br />
                <br />

                <b>3) We use the collected data to provide you with the following services:</b>
                <br />
                <br />

                <b><i>a) User Authentication: </i> </b> Your user ID is used to facilitate your login and ensure the security of your account.
                <br />
                <b><i>b) Live Indian Temple Feature: </i></b> Your location information is used to provide you with a live stream of Indian temples. This feature is designed to enhance your experience and is not used for any other purpose.
                <br />
                <b><i>c) Aarti Notifications: </i></b> If you opt to receive aarti notifications, we will send you timely alerts about upcoming temple aarti events.

                <br />
                <br />
                <b>4) Data Security</b>
                <br />
                <br />

                We take your privacy seriously and implement reasonable security measures to protect your data. We use encryption and access controls to safeguard your information.
                <br />
                <br />

                <b>5) Sharing Your Information</b>
                <br />
                <br />
                We do not share your personal information with third parties, except in the following circumstances:

                With your consent.
                When required by law or to protect our rights, privacy, safety, or property.
                Your Choices
                <br />
                <br />

                <b>6) You have the following options regarding your data:</b>
                <br />
                <br />

                <b><i>a) Access and Correction: </i></b>You can access and correct your user ID or location data through the app settings.
                Notification Preferences: You can manage your notification preferences within the app.
                <br />
                <b><i>b) Data Deletion: </i></b>You can request the deletion of your account and data at any time by from the application and also by contacting us at <a href="mailto:contact@manmandirapp.com">contact@manmandirapp.com.</a>.
                Changes to this Privacy Policy

                We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes through the app or other means.
                <br />
                <br />

                <b>Contact Us</b>
                <br />
                <br/>
                If you have any questions, concerns, or requests related to your privacy or this policy, please contact us at <a href="mailto:contact@manmandirapp.com">contact@manmandirapp.com.</a>

                By using Man Mandir, you consent to the terms of this Privacy Policy. Please read this policy carefully and ensure that you are comfortable with our data handling practices.
            </p>
        </div>
    )
}
