import { initializeApp } from "firebase/app";
import firebase from 'firebase';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_8uEvWeQIh9IRKTU0ve4dO7ZYokeKyeg",
  authDomain: "devdarshan-4df08.firebaseapp.com",
  projectId: "devdarshan-4df08",
  storageBucket: "devdarshan-4df08.appspot.com",
  messagingSenderId: "323071854488",
  appId: "1:323071854488:web:f44784ee62d8dab8360ded"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export default storage;